import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import {itemSitemap} from "../_models/itemSitemap";


@Injectable({
  providedIn: 'root'
})

export class UrlService {
  constructor(private http: HttpClient) {
  }

  getRoute() {
    return this.http.get<itemSitemap[]>(`${environment.api}/api/sitemap`);
  }

}
